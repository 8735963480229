<template>
  <v-row>
    <v-col
      cols="12"
      md="auto">
      <h2>
        Tags
      </h2>
    </v-col>
    <v-spacer />
    <v-col
      cols="12"
      md="5"
      lg="4"
      class="d-flex flex-row align-center justify-end">
      <search-box
        v-model="query.search"
        class="mr-2"
        :disabled="loading"
        @on-search="getItems(true)" />
      <v-btn
        color="secondary"
        :disabled="loading"
        @click="addItem()">
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :item-per-page="query.limit"
        :loading="loading"
        hide-default-footer
        class="elevation-3 mb-2">
        <template #[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <gw-icon-hover
            small
            icon-name="mdi-delete"
            icon-hover="mdi-delete-empty"
            @click="confirmRemoveItem(item)" />
        </template>
      </v-data-table>
      <v-pagination
        v-model="query.page"
        :length="totalPages"
        :disabled="loading"
        total-visible="7"
        color="secondary" />
    </v-col>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span style="font-size: 20px;">Member Tag Form</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="member-tag-form"
              @submit.prevent="onSubmit()">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.name"
                    color="secondary"
                    label="Name"
                    :rules="textBoxRules"
                    outlined
                    dense
                    hide-details
                    :disabled="formLoading" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            :disabled="formLoading"
            @click="onClose()">
            Close
          </v-btn>
          <v-btn
            type="submit"
            color="green darken-1"
            text
            :loading="formLoading"
            @click="onSubmit()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'
import MemberTagsProvider from '@/resources/MemberTagsProvider'

const MemberTagsService = new MemberTagsProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      loading: true,
      formLoading: false,
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '100px'
        }
      ],
      items: [],
      query: {
        page: 1,
        limit: 15,
        search: ''
      },
      totalPages: 1,
      formData: {
        id: '',
        name: ''
      },
      dialog: false,
      textBoxRules: [
        (v) => !!v || 'Field is require.'
      ]
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems(true)
  },
  methods: {
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
        }

        const { data } = await MemberTagsService.getMemberTags(this.query)

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    addItem () {
      this.formData = {
        id: '',
        name: ''
      }
      this.dialog = true
    },
    onClose () {
      this.dialog = false
      this.formData = {
        id: '',
        name: ''
      }
    },
    editItem (item) {
      this.formData = {
        id: item.id,
        name: item.name
      }
      this.dialog = true
    },
    confirmRemoveItem (item) {
      this.$store.dispatch('Components/setModal', {
        value: true,
        title: 'Remove Item',
        message: `Do you want to remove ${item.name}?`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.removeItem(item.id)
      })
    },
    async removeItem (id) {
      try {
        this.loading = true

        await MemberTagsService.deleteMemberTag(id)

        this.getItems(true)
      } catch (error) {
        console.error('removeItem', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.formLoading = true

        const validated = await this.$refs['member-tag-form'].validate()

        if (!validated) {
          return
        }

        if (this.formData?.id) {
          await MemberTagsService.updateMemberTag(this.formData.id, this.formData)
        } else {
          await MemberTagsService.createMemberTag(this.formData)
        }

        this.onClose()
        this.getItems(true)
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.formLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
