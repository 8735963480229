import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class MemberTagsProvider extends HttpRequest {
  getMemberTags (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/member-tags', query)
  }

  createMemberTag (payload) {
    this.setHeader(getAuthToken())
    return this.post('/member-tags', payload)
  }

  updateMemberTag (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/member-tags/${id}`, payload)
  }

  deleteMemberTag (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/member-tags/${id}`)
  }
}

export default MemberTagsProvider
